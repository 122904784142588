import React from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link, Stack, Typography } from '@mui/material';

function Info() {
  return (
    <Stack>
      <Typography sx={{ fontWeight: 'bold' }}>Pluri Coworking Saúde LTDA</Typography>

      <Typography sx={{ fontWeight: 'bold' }}>CNPJ: 44.334.355/0001-92</Typography>

      <Typography sx={{ fontWeight: 'bold' }}>
        Rua Blumenau, 64 - sala 1006 <br /> América - CEP: 89204250
      </Typography>

      <Stack direction="row" spacing={2}>
        <Link
          href="https://www.instagram.com/plurisaude/"
          target="_blank"
          sx={{
            height: 40,
            color: '#333333',
            '&:hover': {
              color: 'primary.main',
            },
          }}
        >
          <InstagramIcon />
        </Link>

        <Link
          href="https://www.linkedin.com/in/pluri-sa%C3%BAde-3b8b3b23b/?originalSubdomain=br"
          target="_blank"
          sx={{
            height: 40,
            color: '#333333',
            '&:hover': {
              color: 'primary.main',
            },
          }}
        >
          <LinkedInIcon />
        </Link>

        <Link
          href="https://www.facebook.com/people/Pluri-Coworking-Sa%C3%BAde/100077985428328/?paipv=0&eav=AfbmDs163v9os5ppERY6jKGqHUdRtc5CszyYHp7ldF_s5cvQuyAWowruwepfZpsWFHk&_rdr"
          target="_blank"
          sx={{
            height: 40,
            color: '#333333',
            '&:hover': {
              color: 'primary.main',
            },
          }}
        >
          <FacebookIcon />
        </Link>
      </Stack>
    </Stack>
  );
}

export default Info;
