import React from 'react';

import { Container, Hidden, Stack, Typography } from '@mui/material';

import Info from './info';
import Logo from './logo';
import NavLink from './navLink';

function Footer() {
  return (
    <>
      <Container component="footer" maxWidth="xl" sx={{ bgcolor: '#f7f7f7', py: { xs: 4, md: 8 } }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 2, md: 0 }}
          justifyContent={{ md: 'space-around' }}
        >
          <Hidden mdDown>
            <Logo />
          </Hidden>

          <Info />
          <NavLink />
        </Stack>
      </Container>

      <Stack alignItems="center" sx={{ py: 2 }}>
        <Typography
          sx={{ fontSize: { xs: 14, md: 16 }, textAlign: { xs: 'center', md: 'inherit' } }}
        >
          ©2023 Pluri Coworking Saúde, Todos os direitos reservados.
        </Typography>
      </Stack>
    </>
  );
}

export default Footer;
