import React from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Hidden, Link, Stack, Typography } from '@mui/material';

function NavLink() {
  return (
    <>
      <Hidden smDown>
        <Stack>
          <Typography
            variant="h5"
            sx={{ color: '#1c223a', fontSize: 20, fontWeight: 'bold', mb: 2 }}
          >
            Pluri Saúde
          </Typography>

          <Stack spacing={2}>
            <Link
              href="/"
              sx={{
                display: 'flex',
                color: '#686b75',
                textDecoration: 'none',
                transition: '0.4s',
                '&:hover': {
                  color: 'primary.main',
                  svg: {
                    mr: '10px',
                  },
                },
              }}
            >
              <KeyboardArrowRightIcon sx={{ ml: '-7px', transition: '0.3s' }} />
              Home
            </Link>

            <Link
              href="/sobre-nos"
              sx={{
                display: 'flex',
                color: '#686b75',
                textDecoration: 'none',
                transition: '0.4s',
                '&:hover': {
                  color: 'primary.main',
                  svg: {
                    mr: '10px',
                  },
                },
              }}
            >
              <KeyboardArrowRightIcon sx={{ ml: '-7px', transition: '0.3s' }} />
              Sobre nós
            </Link>

            <Link
              href="/profissionais"
              sx={{
                display: 'flex',
                color: '#686b75',
                textDecoration: 'none',
                transition: '0.4s',
                '&:hover': {
                  color: 'primary.main',
                  svg: {
                    mr: '10px',
                  },
                },
              }}
            >
              <KeyboardArrowRightIcon sx={{ ml: '-7px', transition: '0.3s' }} />
              Profissionais
            </Link>

            <Link
              href="/contato"
              sx={{
                display: 'flex',
                color: '#686b75',
                textDecoration: 'none',
                transition: '0.4s',
                '&:hover': {
                  color: 'primary.main',
                  svg: {
                    mr: '10px',
                  },
                },
              }}
            >
              <KeyboardArrowRightIcon sx={{ ml: '-7px', transition: '0.3s' }} />
              Contato
            </Link>
          </Stack>
        </Stack>
      </Hidden>

      <Stack>
        <Typography variant="h5" sx={{ color: '#1c223a', fontSize: 20, fontWeight: 'bold', mb: 2 }}>
          Outros
        </Typography>

        <Stack spacing={2}>
          <Link
            href="https://admin.plurisaude.com"
            target="_blank"
            sx={{
              display: 'flex',
              color: '#686b75',
              textDecoration: 'none',
              transition: '0.4s',
              '&:hover': {
                color: 'primary.main',
                svg: {
                  mr: '10px',
                },
              },
            }}
          >
            <KeyboardArrowRightIcon sx={{ ml: '-7px', transition: '0.3s' }} />
            Acesso restrito
          </Link>
        </Stack>
      </Stack>
    </>
  );
}

export default NavLink;
