import React, { useEffect, useState } from 'react';

import { Avatar, Box, Card, Container, Grid, Stack, Typography } from '@mui/material';

import Loading from 'components/loading';
import Search from 'components/search';

import Api from 'services/api';

function Professionals() {
  const [list, setList] = useState([]);
  const [listFiltered, setListFiltered] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllProfessionals();

    async function getAllProfessionals() {
      setLoading(true);
      try {
        const response = await Api.get('professionals/all');
        const { data } = response.data;

        setList(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  function renderProfessionals() {
    const ls = listFiltered || list;

    return ls.map((item, index) => (
      <Grid key={index} xs={12} sm={6} lg={4} xl={3} item>
        <Card
          sx={{
            minHeight: { xs: 'auto', sm: 305 },
            p: 2,
            bgcolor: 'transparent',
            border: '1px solid #D0D0D2',
            borderRadius: '10px',
            boxShadow: 'none',
            transition: '0.3s',
            transform: 'translateY(0)',
            '&:hover': {
              borderColor: '#eef2f6',
              boxShadow: '0 5px 50px 0 rgba(177, 177, 177, 0.4)',
              transform: 'translateY(-5px)',
            },
          }}
        >
          <Stack direction="row" spacing={2}>
            <Avatar src={item.photo} sx={{ width: 72, height: 72 }} />

            <Stack>
              <Typography
                variant="h6"
                sx={{ color: 'primary.main', fontSize: { xs: 16, sm: 20 }, fontWeight: 'bold' }}
              >
                {item.name}
              </Typography>

              <Typography variant="caption">{item.specialty}</Typography>

              <Typography variant="caption">{item.phone}</Typography>
            </Stack>
          </Stack>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2">{item.presentation}</Typography>
          </Box>
        </Card>
      </Grid>
    ));
  }

  function searchFilter(e) {
    const query = e.target.value;

    if (query !== '') {
      const ls = list.filter(({ name, specialty }) => {
        return (
          name.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          specialty.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });

      setListFiltered(ls);
    } else {
      setListFiltered(null);
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <Container maxWidth="lg" sx={{ pt: 8, pb: 12 }}>
      <Stack spacing={1}>
        <Typography
          variant="h5"
          sx={{
            color: 'primary.main',
            fontSize: { xs: 30, sm: 36 },
            fontWeight: 'bold',
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
        >
          Conheça os profissionais que atuam com a Pluri
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontSize: 16,
            textAlign: 'center',
          }}
        >
          Estes especialistas utilizam o nosso Coworking para atender os seus pacientes com conforto
          e privacidade.
        </Typography>
      </Stack>

      <Grid spacing={3} sx={{ pt: { xs: 5, sm: 10 } }} container>
        <Grid xs={12} sm={6} lg={4} xl={3} item>
          <Search
            placeholder="Busque por nome ou especialidade"
            onChange={(e) => searchFilter(e)}
          />
        </Grid>
      </Grid>

      <Grid spacing={3} sx={{ pt: { xs: 3, sm: 4 } }} container>
        {renderProfessionals()}
      </Grid>
    </Container>
  );
}

export default Professionals;
