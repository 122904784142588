import React from 'react';
import { useLocation } from 'react-router-dom';

import { Link } from '@mui/material';

function NavLink(link, index) {
  const location = useLocation();

  return (
    <Link
      key={index}
      href={link.path}
      underline="none"
      sx={{
        color: '#212322',
        fontSize: 14,
        fontWeight: 300,
        textDecoration: 'none',
        ...(location.pathname === link.path && {
          color: 'primary.main',
          fontWeight: 'bold',
        }),
      }}
    >
      {link.label}
    </Link>
  );
}

export default NavLink;
