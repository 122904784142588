import React from 'react';

import { Box, Button, Card, Container, Hidden, Stack, Typography } from '@mui/material';

import Carousel from 'better-react-carousel';

function Header() {
  const banners = ['banner-1.png', 'banner-2.png', 'banner-3.png'];

  function renderBanners() {
    return banners.map((banner, i) => (
      <Carousel.Item key={i}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(assets/images/home/${banner})`,
            backgroundPosition: '50% 65%',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'scroll',
            backgroundSize: 'cover',
          }}
        />
      </Carousel.Item>
    ));
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{ width: '100%', height: { xs: 300, sm: '80vh' }, position: 'relative' }}
      >
        <Hidden mdDown>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              maxWidth: '80%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2,
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="flex-start"
              sx={{ width: '75%', height: 'auto', minHeight: '100%' }}
            >
              <Card
                sx={{
                  maxWidth: 430,
                  height: '100%',
                  p: '10px 40px 20px',
                  borderRadius: '10px',
                }}
                elevation={0}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: 'antipasto-bold',
                    color: 'primary.main',
                    fontSize: 38,
                    fontWeight: 'bold',
                    mt: 2.5,
                  }}
                >
                  Pluri Coworking
                </Typography>

                <Typography
                  variant="h2"
                  sx={{ color: '#333', fontSize: 22, fontWeight: 'bold', mt: 1.5 }}
                >
                  Com a Pluri você conta com <br /> a infraestrutura ideal para atender os seus
                  pacientes <br /> da melhor forma.
                </Typography>

                <Typography sx={{ color: '#333', fontSize: 16, fontWeight: 500, my: 3.5 }}>
                  Aqui você encontra consultórios modernos e aconchegantes, mas sem <br /> a
                  burocracia e custo de um consultório tradicional.
                </Typography>

                <Button
                  variant="contained"
                  size="large"
                  sx={{ p: '12px 30px', mb: 1.5, borderRadius: '10px', fontWeight: 'bold' }}
                  href="https://api.whatsapp.com/send?phone=554791139046"
                  target="_blank"
                  disableElevation
                  fullWidth
                >
                  Entre em contato
                </Button>
              </Card>
            </Stack>
          </Stack>
        </Hidden>

        <div id="home-carousel">
          <Carousel
            containerStyle={{ width: '100%', height: '100%' }}
            autoplay={5000}
            gap={0}
            hideArrow
            loop
          >
            {renderBanners()}
          </Carousel>
        </div>
      </Stack>

      <Hidden mdUp>
        <Container sx={{ mt: 3 }}>
          <Typography
            variant="h1"
            sx={{
              fontFamily: 'antipasto-bold',
              color: 'primary.main',
              fontSize: 32,
              fontWeight: 'bold',
            }}
          >
            Pluri Coworking
          </Typography>

          <Typography
            variant="h2"
            sx={{ color: '#333', fontSize: 18, fontWeight: 'bold', mt: 1.5 }}
          >
            Com a Pluri você conta com a infraestrutura ideal para atender os seus pacientes da
            melhor forma.
          </Typography>

          <Typography sx={{ color: '#333', fontSize: 16, fontWeight: 500, my: 2 }}>
            Aqui você encontra consultórios modernos <br /> e aconchegantes, mas sem a burocracia e
            custo de um consultório tradicional.
          </Typography>

          <Button
            variant="contained"
            size="large"
            sx={{ p: '12px 30px', borderRadius: '10px', fontWeight: 'bold' }}
            href="https://api.whatsapp.com/send?phone=554791139046"
            target="_blank"
            disableElevation
            fullWidth
          >
            Entre em contato
          </Button>
        </Container>
      </Hidden>
    </>
  );
}

export default Header;
