import React from 'react';

import Benefits from './benefits';
import Feedbacks from './feedbacks';
import Header from './header';
import Plans from './plans';

function Home() {
  return (
    <>
      <Header />
      <Benefits />
      <Plans />
      <Feedbacks />
    </>
  );
}

export default Home;
