import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Hidden, IconButton, Link, Stack, Toolbar } from '@mui/material';

import Links from './links';
import MobileLinks from './mobileLinks';
import NavLink from './navLink';

function Header() {
  const [mobileLinks, setMobileLinks] = useState(false);

  function handleChangeMobileLinks() {
    const isOpen = mobileLinks !== true;
    setMobileLinks(isOpen);
  }

  return (
    <AppBar
      component="header"
      position="sticky"
      elevation={0}
      sx={{
        borderTop: ({ palette }) => `4px solid ${palette.primary.main}`,
        bgcolor: 'white',
        boxShadow: '0px 1px 2px 0px rgba(60,64,67,.3), 0px 1px 3px 1px rgba(60,64,67,.15)',
      }}
    >
      <Toolbar>
        <Link href="/" sx={{ py: 1.5 }}>
          <img
            alt="logo"
            src="assets/images/logos/purple.png"
            style={{ width: 100, height: 'auto' }}
          />
        </Link>

        <Box flexGrow={1} />

        <Hidden mdDown>
          <Stack direction="row" spacing={3}>
            {Links.map(NavLink)}
          </Stack>
        </Hidden>

        <Hidden mdUp>
          <IconButton onClick={handleChangeMobileLinks}>
            <MenuIcon sx={{ fontSize: 28 }} />
          </IconButton>
        </Hidden>
      </Toolbar>

      <MobileLinks isOpen={mobileLinks} />
    </AppBar>
  );
}

export default Header;
