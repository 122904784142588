import React, { useRef, useState } from 'react';

import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Card, Container, Grid, Hidden, Stack, Typography } from '@mui/material';

import { Form } from '@unform/web';

import CustomTextField from 'components/forms/textField';

import Api from 'services/api';
import * as Yup from 'yup';

function Contact() {
  const formRef = useRef(null);

  const items = [
    {
      icon: <HomeIcon />,
      title: 'Endereço do escritório',
      subtitle: 'Rua, Blumenau n° 64 sala 1006, 10° andar - edifício comercial Adville',
    },
    {
      icon: <WhatsAppIcon />,
      title: 'WhatsApp',
      subtitle: '+55 (47) 9113-9046',
    },
    {
      icon: <EmailIcon />,
      title: 'Endereço de email',
      subtitle: 'Pluricoworkingsaude@gmail.com',
    },
  ];

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  function renderItems() {
    return items.map((item, i) => (
      <Grid key={i} xs={12} md={4} item>
        <Card
          sx={{
            height: { xs: 'auto', md: 252 },
            p: { xs: 2, md: 5 },
            bgcolor: 'transparent',
            border: '1px solid #D0D0D2',
            borderRadius: '10px',
            boxShadow: 'none',
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              svg: {
                width: 40,
                height: 40,
                color: 'primary.main',
              },
            }}
          >
            {item.icon}
          </Box>

          <Stack alignItems="center" spacing={{ xs: 1, md: 2 }} sx={{ mt: { xs: 1, md: 4 } }}>
            <Typography variant="h4" sx={{ fontSize: { xs: 18, sm: 22 }, fontWeight: 'bold' }}>
              {item.title}
            </Typography>

            <Typography
              sx={{ color: '#716c80', fontSize: { xs: 12, sm: 16 }, textAlign: 'center' }}
            >
              {item.subtitle}
            </Typography>
          </Stack>
        </Card>
      </Grid>
    ));
  }

  function handleSubmitForm(formData) {
    setLoading(true);
    setAlert(null);

    validateFields(formData);
  }

  async function validateFields(formData) {
    formRef.current.setErrors({});

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('É obrigatório informar um nome'),
        mail: Yup.string()
          .required('É obrigatório informar um e-mail')
          .email('Informe um e-mail válido'),
        subject: Yup.string().required('É obrigatório informar um assunto'),
        message: Yup.string().required('É obrigatório informar uma mensagem'),
      });

      await schema.validate(formData, { abortEarly: false });

      send(formData);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
        setLoading(false);
      }
    }
  }

  async function send(payload) {
    try {
      const response = await Api.post('contact/send', payload);
      const { message } = response.data;

      formRef.current.reset();

      setAlert({ type: 'success', message });
    } catch (error) {
      setAlert({
        type: 'error',
        message: 'Ops! Ocorreu um erro inesperado, entre em contato com o suporte.',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container component="section" maxWidth="lg" sx={{ pt: 1, pb: 12 }}>
      <Grid spacing={2} sx={{ mt: 3 }} container>
        {renderItems()}
      </Grid>

      <Grid spacing={4} sx={{ mt: 6 }} container>
        <Grid xs={12} md={5} item>
          <iframe
            style={{ border: 'none', width: '100%', height: '406px' }}
            src={`https://maps.google.com/maps?q=Rua Blumenau,64 - Joinville/SC&t=&z=13&ie=UTF8&iwloc=&output=embed`}
          />
        </Grid>

        <Hidden mdDown>
          <Grid component={Form} ref={formRef} md={7} item onSubmit={handleSubmitForm}>
            <Typography variant="h5" sx={{ fontSize: 28, fontWeight: 'bold', mb: 3 }}>
              Entre em contato conosco
            </Typography>

            <Grid spacing={3} container>
              {alert && (
                <Grid xs={12} item>
                  <Alert severity={alert.type}>{alert.message}</Alert>
                </Grid>
              )}

              <Grid md={6} item>
                <CustomTextField
                  label="Nome"
                  labelProps={{ sx: { fontWeight: 'bold' } }}
                  name="name"
                  size="small"
                />
              </Grid>

              <Grid md={6} item>
                <CustomTextField
                  label="E-mail"
                  labelProps={{ sx: { fontWeight: 'bold' } }}
                  name="mail"
                  size="small"
                />
              </Grid>

              <Grid md={12} item>
                <CustomTextField
                  label="Assunto"
                  labelProps={{ sx: { fontWeight: 'bold' } }}
                  name="subject"
                  size="small"
                />
              </Grid>

              <Grid md={12} item>
                <CustomTextField
                  label="Sua mensagem"
                  labelProps={{ sx: { fontWeight: 'bold' } }}
                  name="message"
                  rows={8}
                  multiline
                />
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{
                minWidth: 160,
                height: 48,
                borderRadius: '10px',
                fontWeight: 'bold',
                textTransform: 'none',
                mt: 3,
              }}
              loading={loading}
              disableElevation
            >
              Enviar
            </LoadingButton>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
}

export default Contact;
