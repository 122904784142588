import React, { useEffect, useRef, useState } from 'react';

import { Stack, TextField, Typography } from '@mui/material';

import { useField } from '@unform/core';

function CustomTextField({
  name,
  label = '',
  labelProps = {},
  helperText,
  defaultValue,
  InputLabelProps,
  ...restProps
}) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue: defaultFieldValue, registerField, error } = useField(name);

  const defaultInputValue = defaultValue ?? defaultFieldValue;

  const [shrink, setShrink] = useState(!!defaultInputValue);

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        setValue(ref, value) {
          if (ref) {
            const newValue = value ?? '';
            ref.value = newValue;
            setShrink(!!newValue);
          }
        },
        clearValue(ref, resetValue) {
          const newValue = resetValue ?? defaultInputValue ?? '';
          ref.value = newValue;
          setShrink(!!newValue);
        },
      });
    }
  }, [fieldName, registerField, defaultInputValue, setShrink]);

  useEffect(() => {
    const input = inputRef.current;

    function handlerFocusEvent(evt) {
      const inputValue = evt.currentTarget.value;
      if (!inputValue) setShrink(true);
    }

    function handlerBlurEvent(evt) {
      const inputValue = evt.target.value;
      if (!inputValue) setShrink(false);
    }

    if (input) {
      input.addEventListener('focus', handlerFocusEvent);
      input.addEventListener('blur', handlerBlurEvent);
    }

    return () => {
      if (input) {
        input.removeEventListener('focus', handlerFocusEvent);
        input.removeEventListener('blur', handlerBlurEvent);
      }
    };
  }, [inputRef]);

  return (
    <Stack spacing={0.5}>
      {label && (
        <Typography component="label" variant="body2" {...labelProps}>
          {label}
        </Typography>
      )}

      <TextField
        name={fieldName}
        error={!!error}
        helperText={error || helperText}
        inputRef={inputRef}
        defaultValue={defaultInputValue}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: InputLabelProps?.shrink ?? shrink,
        }}
        {...restProps}
      />
    </Stack>
  );
}

export default CustomTextField;
