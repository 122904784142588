import React from 'react';
import { useRoutes } from 'react-router-dom';

import MainLayout from 'layouts/main';

import AboutUsView from 'views/aboutUs';
import ContactView from 'views/contact';
import HomeView from 'views/home';
import ProfessionalsView from 'views/professionals';

function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <HomeView /> },
        { path: '/contato', element: <ContactView /> },
        { path: '/sobre-nos', element: <AboutUsView /> },
        { path: '/profissionais', element: <ProfessionalsView /> },
      ],
    },
  ]);

  return routes;
}

export default Router;
