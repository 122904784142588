import React from 'react';

import { Button, Card, Container, Grid, Hidden, Stack, Typography } from '@mui/material';

function Plans() {
  const items = [
    { title: 'Mensal', price: 'R$ 299,00' },
    { title: 'Trimestral', price: 'R$ 249,00' },
    { title: 'Semestral', price: 'R$ 199,00' },
  ];

  function renderPlans() {
    return items.map((plan, i) => (
      <Grid key={i} xs={12} md={4} item>
        <Card
          sx={{
            p: '60px 30px',
            borderRadius: '10px',
            boxShadow: '10px 10px 25px 0 rgba(0,0,0,.14)',
            textAlign: 'center',
            transform: 'translateY(0)',
            transition: '0.3s',
            '&:hover': {
              boxShadowL: '0 5px 50px 0 rgba(177, 177, 177, 0.4)',
              transform: 'translateY(-5px)',
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: 'primary.dark',
              fontSize: 16,
              fontWeight: 'bold',
              mb: 3,
            }}
          >
            {plan.title}
          </Typography>

          <Typography
            variant="h1"
            sx={{ color: '#1c223a', fontSize: 60, fontWeight: 'bold', mb: 1 }}
          >
            {plan.price}
          </Typography>

          <Typography variant="h6" sx={{ fontSize: 14, fontWeight: 'bold' }}>
            + R$40 por hora trabalhada
          </Typography>

          <Button
            variant="contained"
            size="large"
            sx={{ height: 45, borderRadius: '10px', boxShadow: 'none', mt: 8 }}
            href="https://api.whatsapp.com/send?phone=554791139046"
            target="_blank"
            fullWidth
          >
            Assinar plano
          </Button>
        </Card>
      </Grid>
    ));
  }

  return (
    <Container maxWidth="xl" sx={{ pt: 5, pb: 6, bgcolor: '#f7f7f7' }}>
      <Stack spacing={1}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'antipasto-bold',
            fontSize: 38,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
        >
          <Hidden smDown>Conheça nossos planos</Hidden>

          <Hidden smUp>Nossos planos</Hidden>
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontSize: 18,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            textAlign: 'center',
            span: {
              color: { xs: 'primary.main', sm: 'inherit' },
            },
          }}
        >
          Escolha o plano que mais se encaixe com o seu <span>momento profissional</span>
        </Typography>
      </Stack>

      <Grid spacing={2} sx={{ mt: 5 }} container>
        {renderPlans()}
      </Grid>
    </Container>
  );
}

export default Plans;
