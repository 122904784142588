import React from 'react';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { InputBase, styled } from '@mui/material';

const MuiSearch = (props) => {
  return <StyledInputBase {...props} startAdornment={<SearchIcon />} />;
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  height: 45,
  padding: '0 1rem',
  color: theme.palette.text.primary,
  fontWeight: 500,
  border: '1px solid #D0D0D2',
  borderRadius: '10px',
}));

const SearchIcon = styled(SearchOutlinedIcon)(() => ({
  fontSize: 16,
  marginRight: 10,
  marginBottom: 3,
}));

export default MuiSearch;
