import React from 'react';

import {
  Avatar,
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  alpha,
} from '@mui/material';

function Benefits() {
  const items = [
    {
      icon: 'assets/icons/time-is-money.png',
      title: 'Pague por hora de uso',
      description:
        'Esqueça os gastos e preocupações de manter a estrutura de um consultório e pague somente pelas horas utilizadas, fazendo um único pagamento por mês.',
    },
    {
      icon: 'assets/icons/schedule.png',
      title: 'Secretária para agendamentos',
      description: 'Contamos com equipe de secretárias para auxiliar nos agendamentos. ',
    },
    {
      icon: 'assets/icons/social-media-marketing.png',
      title: 'Marketing personalizado',
      description:
        'Promova suas redes sociais e impulsione sua carreira através dos nossos serviços de marketing.',
    },
  ];

  function renderBenefits() {
    return items.map((item, i) => (
      <Grid key={i} xs={12} md={4} item>
        <Card
          sx={{
            minHeight: '100%',
            p: 3,
            bgcolor: 'transparent',
            color: '#636379',
            border: '1px solid #D0D0D2',
            borderRadius: '10px',
            boxShadow: 'none',
            transition: 'all linear 0.3s',
          }}
        >
          <Avatar
            sx={{
              width: 64,
              height: 64,
              bgcolor: 'primary.main',
              mb: 4,
              color: 'black',
            }}
          >
            <img
              alt="icon"
              src={item.icon}
              style={{ width: 35, height: 35, filter: 'brightness(100)' }}
            />
          </Avatar>

          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: 18, sm: 24 },
              fontWeight: 'bold',
              textTransform: 'uppercase',
              mb: 1,
            }}
          >
            {item.title}
          </Typography>

          <Typography sx={{ fontSize: { xs: 14, sm: 16 }, color: '#12141d' }}>
            {item.description}
          </Typography>
        </Card>
      </Grid>
    ));
  }

  return (
    <Stack sx={{ pt: 3, pb: 6 }}>
      <Box
        sx={{ py: 4, color: 'white', bgcolor: ({ palette }) => alpha(palette.primary.main, 0.85) }}
      >
        <Grid spacing={{ xs: 2, sm: 4, md: 0 }} container>
          <Grid xs={12} sm={6} md={3} item>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: 28, md: 38 },
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Consultórios
            </Typography>

            <Typography sx={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}>
              decorados e equipados
            </Typography>
          </Grid>

          <Grid xs={12} sm={6} md={3} item>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: 28, md: 38 },
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Coliving
            </Typography>

            <Typography sx={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}>
              decorado para momentos de descontração
            </Typography>
          </Grid>

          <Grid xs={12} sm={6} md={3} item>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: 28, md: 38 },
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Networking
            </Typography>

            <Typography sx={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}>
              com profissionais da saúde
            </Typography>
          </Grid>

          <Grid xs={12} sm={6} md={3} item>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: 28, md: 38 },
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Localização
            </Typography>

            <Typography sx={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}>
              na região central da cidade
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Container maxWidth="xl" sx={{ pt: { xs: 4, sm: 8 } }}>
        <Stack spacing={1} alignItems="center">
          <Typography
            sx={{
              fontFamily: 'antipasto-bold',
              fontSize: 38,
              fontWeight: 300,
              lineHeight: { xs: '40px', sm: '50px' },
              textTransform: 'uppercase',
              textAlign: 'center',
              b: { display: 'block', color: 'primary.main' },
            }}
          >
            Praticidade e conforto para
            <b>você e seus pacientes</b>
          </Typography>

          <Divider sx={{ width: 170, borderWidth: 1, borderColor: 'primary.main' }} />
        </Stack>

        <Grid sx={{ pt: 6 }} spacing={2} container>
          {renderBenefits()}
        </Grid>
      </Container>
    </Stack>
  );
}

export default Benefits;
