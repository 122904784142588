import React from 'react';

import { Box, CircularProgress } from '@mui/material';

function Loading() {
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'white',
        zIndex: 1,
      }}
    >
      <CircularProgress size={40} />
    </Box>
  );
}

export default Loading;
