import React from 'react';

import { Avatar, Box, Container, Grid, Stack, Typography, useTheme } from '@mui/material';

import Carousel from 'better-react-carousel';

function Feedbacks() {
  const { palette } = useTheme();

  const assessments = [
    {
      photo: '/assets/images/home/feedback-1.jpeg',
      name: 'Dra Flavia Fontana Franco',
      msg: `Atender na pluri é muito agradável; o ambiente é tranquilo, além de ser um lugar onde podemos trocar experiências com colegas que atuam também em outras áreas tanto da psicologia quanto nutrição. Trazendo ainda mais benefícios para nossos pacientes!`,
      profession: 'Médica - Especialistas em Saúde Mental',
    },
    {
      photo: '/assets/images/home/feedback-2.jpeg',
      name: 'Dra Andressa Zanluca',
      msg: `Encontrei na Pluri o que eu busco para os meus atendimentos, um lugar aconchegante, organizado e empático, onde consigo atender meus pacientes da maneira que eles merecem.`,
      profession: 'Médica - Especialistas em Saúde Mental',
    },
    {
      photo: '/assets/images/home/feedback-3.jpeg',
      name: 'Dra Enaise Vodonós',
      msg: 'Amo atender na Pluri! Um ambiente moderno, lindo e com consultórios super completos!! E numa excelente localização da cidade!',
      profession: 'Médica - Especialista em Clínica Médica',
    },
  ];

  function renderAssessments(item, index) {
    return (
      <Carousel.Item key={index}>
        <Stack alignItems="center" sx={{ mb: 5 }}>
          <Typography
            sx={{ color: 'primary.dark', fontSize: 70, letterSpacing: '-.02em', mb: '-50px' }}
          >
            ’’
          </Typography>

          <Typography
            component="span"
            sx={{
              minHeight: 120,
              color: '#1c223a',
              fontSize: 20,
              textAlign: 'center',
              mb: '30px',
            }}
          >
            {item.msg}
          </Typography>

          <Avatar src={item.photo} sx={{ width: 72, height: 72, mb: 1 }} />

          <Typography variant="h5" sx={{ color: '#1c223a', fontSize: 16, fontWeight: 500 }}>
            {item.name}
          </Typography>

          <Typography
            variant="body2"
            sx={{ color: '#686b75', fontSize: 14, textAlign: { xs: 'center', sm: 'initial' } }}
          >
            {item.profession}
          </Typography>
        </Stack>
      </Carousel.Item>
    );
  }

  function renderDot({ isActive }) {
    return (
      <Box
        component="span"
        sx={{
          width: 12,
          height: 12,
          borderRadius: '100%',
          background: '#e0e0e0',
          margin: '5px',
          transition: '0.3s',
          ...(isActive && {
            background: `linear-gradient(${palette.primary.main}, ${palette.primary.light})`,
          }),
        }}
      />
    );
  }

  return (
    <Container maxWidth="xl" sx={{ pt: 3, pb: 6 }}>
      <Grid justifyContent="center" container>
        <Grid xs={12} sm={8} item>
          <Carousel rows={1} autoplay={3000} dot={renderDot} hideArrow showDots loop>
            {assessments.map(renderAssessments)}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Feedbacks;
