import React, { useMemo } from 'react';

import {
  CssBaseline,
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material';

import GlobalStyles from './globalStyles';
import Palette from './palette';
import Typography from './typography';

function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette: Palette(),
      shape: { borderRadius: 8 },
      typography: Typography(),
    }),
    []
  );

  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeProvider;
