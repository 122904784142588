import React from 'react';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Fab } from '@mui/material';

function WhatsAppFab() {
  return (
    <Fab
      href="https://api.whatsapp.com/send?phone=554791139046"
      target="_blank"
      sx={{
        width: { xs: 56, md: 70 },
        height: { xs: 56, md: 70 },
        position: 'fixed',
        top: '87%',
        right: '2.5%',
        bgcolor: '#25d366',
        '&:hover': {
          bgcolor: '#25d366',
        },
      }}
      disableFocusRipple
      disableRipple
    >
      <WhatsAppIcon sx={{ fontSize: 30 }} />
    </Fab>
  );
}

export default WhatsAppFab;
