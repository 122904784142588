import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from './footer';
import Header from './header';
import WhatsAppFab from './whatsAppFab';

function Main() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <WhatsAppFab />
    </>
  );
}

export default Main;
