import React from 'react';

import { Box, Container, Grid, Hidden, Typography } from '@mui/material';

function AboutUs() {
  return (
    <>
      <Box
        sx={{
          height: { xs: 300, sm: 400 },
          backgroundImage: 'url(assets/images/aboutUs/banner.jpg)',
          backgroundPosition: '50%',
          backgroundSize: 'cover',
        }}
      />

      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Grid spacing={3} container>
          <Grid md={6} item>
            <Typography
              variant="h1"
              sx={{
                color: 'primary.main',
                fontSize: 28,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              Conheça a Pluri Coworking
            </Typography>

            <Typography sx={{ mt: 4, fontSize: 16, fontWeight: 500 }}>
              A Pluri nasceu em 2022 com desejo de levar aos profissionais de saúde a praticidade de
              abrir o seu consultório sem a burocracia e altos custos de um consultório tradicional,
              o nosso objetivo é otimizar a carreira de novos profissionais oferecendo serviços de
              marketing, network e atendimento enquanto você foca em oferecer o melhor para os seus
              pacientes.
              <br />
              <br />
              Para os novos profissionais de saúde aqui você encontra o seu primeiro consultório,
              para os mais experientes oferecemos praticidade. Contamos com estruturas modernas e
              aconchegantes, para você atender os seus pacientes com conforto, temos também um
              espaço de coliving, para a interação
              <Hidden mdDown>
                <br />
              </Hidden>
              e descontração entre os profissionais parceiros.
            </Typography>
          </Grid>

          <Grid md={6} item>
            <img src="assets/images/aboutUs/room.jpg" alt="" />
          </Grid>
        </Grid>

        <Grid spacing={3} sx={{ mt: 6 }} container>
          <Hidden mdDown>
            <Grid md={6} item>
              <img src="assets/images/aboutUs/partners.jpg" alt="" />
            </Grid>
          </Hidden>

          <Grid md={6} item>
            <Typography
              variant="h1"
              sx={{
                color: 'primary.main',
                fontSize: 28,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              Quatro profissionais com um objetivo em comum
            </Typography>

            <Typography sx={{ mt: 3, fontSize: 16, fontWeight: 500 }}>
              Fundada em 2022 e com muita oportunidade e idealização de expansão, a Pluri nasceu do
              objetivo de seus quatro fundadores em oferecer um serviço de coworking de saúde com
              diversos serviços que impulsionasse a carreira de diversos profissionais. Acreditamos
              que devemos fazer a diferença na carreira das pessoas, por isso somos o primeiro
              Coworking de Saúde de Joinville a oferecer um serviço completo de assistência a novos
              profissionais.
              <br />
              <br />
              Fundadores da Pluri: Luis Otavio da Veiga é engenheiro de produção, Salomão Lee é
              médico, Osiel Schott é médico e Rafael Luis da Veiga é administrador. Cada um
              contribuiu com seus conhecimentos e experiências para assim desenvolver a Pluri.
            </Typography>
          </Grid>

          <Hidden mdUp>
            <Grid xs={12} item>
              <img src="assets/images/aboutUs/partners.jpg" alt="" />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </>
  );
}

export default AboutUs;
